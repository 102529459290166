import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AllowedDaysList, CustomerTypeList, DiscountTypeList, Status, ValidityList } from 'src/app/_models/coupon-management';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { HttpParams } from '@angular/common/http';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ProductsService } from 'src/app/_services/products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CustomerSalesChannelService } from 'src/app/_services/sales-channel.services';
import { CustomerCouponCodeService } from 'src/app/_services/coupon-codes.services';


@Component({
  selector: 'app-coupon-codes-form',
  templateUrl: './coupon-codes-form.component.html',
  styleUrls: ['./coupon-codes-form.component.scss']
})
export class CouponCodesFormComponent implements OnInit {
 
  @ViewChild('op') op: OverlayPanel
  
  _global = GlobalService
  destroy$: Subject<boolean> = new Subject<boolean>()
  
  editform: FormGroup
  // Select Products 
    isLoading:boolean = false;
    searchText = ''
    lastSearchText: string;
    isListLoading: boolean;
    dataList: any = []
    searchList: any = []
    formValues:any;
    isProductLoad:boolean = false;
// input Field Description
maxDiscountDesc = `Please enter "0" to remove the Max discount restriction.`
maxCouponUseDesc =`Set usage limit (e.g., 100 for first 100 customers). Leave it blank for unlimited use.`


  couponData;
  allowedCouponsDummy = []
  // Bind the dropdown values to the form from the service model 
  salesChannelList:any[] = [];
  statusList:any[] = Status;
  customerTypeList = CustomerTypeList;
  discountTypeList = DiscountTypeList;
  validityList = ValidityList;
  allowedDaysList = AllowedDaysList;
  appliedOn:any[] =[
    {id:1,viewValue:'Apply to all Products'},
    {id:2,viewValue:'Selected Products'},
    {id:3,viewValue:'Apply to Category'},
  ]
  allowedLocationsList:any[]= [];
  selectedSubscriptionPlanList:any[]=[]

  maxValue:number;
  subscriptionPlanDropdownIcon:string ='pi pi-chevron-down';
  selectedAppNotificationText: any;
  genratedLink:any;


  @Input() isNew: boolean = true
  @Input() isProduct: boolean = true
  @Input() fromList: boolean = true
  @Input() isViewOnly: boolean 
  @Output() onSubmit = new EventEmitter();
  
  constructor(
    private _fb: FormBuilder,
    public _couponCodeService: CustomerCouponCodeService,
    private _connMessageService: ConnMessageService,
    public _businessSetupService: BusinessSetupService,
    public _subscriptionPlanService: SubscriptionPlanService,
    private _salesChannelService: CustomerSalesChannelService,
    public _abd: AppBaseDataService,
    private clipboard: Clipboard,
    private _productService: ProductsService,
    private _spinner: NgxSpinnerService,
    private _messageService: MessageService,
    ) { }


  ngOnInit(): void {
    let url = this._couponCodeService.activeItem?.printText
    let urlUpdate; 
    if(!this.isNew){
     urlUpdate = url.split('trackingId=')[0].slice(0, -1)
    }else{
      urlUpdate = this._global.whiteLabel.siteURL;
    }
    this.couponData = {
      discountName: this._couponCodeService.activeItem?.discountName,
      appNotificationText: this._couponCodeService.activeItem?.salesChannel?.id,
      customerType: this._couponCodeService.activeItem?.customerType,
      discountValueType: this._couponCodeService.activeItem?.discountValueType,
      discountValue: this._couponCodeService.activeItem?.discountValue,
      maximumAllowed: this._couponCodeService.activeItem?.maximumAllowed,
      maxCouponUse: this._couponCodeService.activeItem?.maxCouponUse,
      validity: this._couponCodeService.activeItem?.validity,
      validityStartDate: this._couponCodeService.activeItem?.validityStartDate,
      validityEndDate: this._couponCodeService.activeItem?.validityEndDate,
      allowedDaysList: this._couponCodeService.activeItem?.allowedDaysList,
      allowedLocationsList: this._couponCodeService.activeItem?.allowedLocationsList,
      isActive: this._couponCodeService.activeItem?.isActive  === true ? 1 : 0,
      applyOn: this._couponCodeService.activeItem?.applyOn || 1,
      category: this._couponCodeService.activeItem?.category,
      couponCodesList: this._couponCodeService.activeItem?.couponCodesList,
      printText: urlUpdate,
    }
    this.getSalesChannel()
    this.getSubscriptionPlans()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  copy(text:any){
    this.clipboard.copy(text);
     this._connMessageService.showToastMessage('copied successfully', 'success');
   }
 
  getSalesChannel(){
       this._abd.showSpinner()
        this._salesChannelService.getAllSalesChannel().subscribe(res => {
        
          this.initForm();
          this.setCustomValidator()
          if (res.success) {
          this._abd.hideSpinner()
          const salesChannelList = res.data
             this.salesChannelList = salesChannelList.map((v)=>{
                 return{
                 id: +v.id,
                 viewValue:v.fullName
                }
              
              })
             let value = this._couponCodeService.activeItem?.salesChannel?.id
             if(value !== undefined){
            this.selectedAppNotificationText =this.salesChannelList.filter((f)=>f.id === +value)[0]
             }
             this.f.appNotificationText.setValue(this.selectedAppNotificationText);
             this.f.appNotificationText.updateValueAndValidity()
         }
       },(error)=>{
        //  console.log(error)
        this.initForm();
        this.setCustomValidator()
       })
  }
  getSubscriptionPlans(){
        this.subscriptionPlanDropdownIcon = 'pi pi-spin pi-spinner';
        this._subscriptionPlanService.getSubscriptionPlanList().subscribe(res => {
        if (res.success) {
          this.subscriptionPlanDropdownIcon = 'pi pi-chevron-down';
          const subscriptionPlans = res.data
          let couponCodeList  = subscriptionPlans.map((v)=>{ 
               return{
                 id: +v.id,
                 viewValue:v.title
                }
              })
              this.allowedLocationsList = couponCodeList.filter((x)=>x.id !== 162851695001 && x.id !== 1628516995005)
                this._couponCodeService.activeItem.allowedLocationsList.map(y=>{
               this.allowedLocationsList.map(x=>{
                if(x.id==y){
                  this.selectedSubscriptionPlanList.push(x);
                }
              })
            })
            this.f.allowedLocationsList.setValue(this.selectedSubscriptionPlanList)
         }
       })
  }

  get f() {
    return this.editform.controls;
  }

  initForm() {
    let allowedDaysDataHolder = []
    if(this._couponCodeService !== undefined){
        this._couponCodeService.activeItem.allowedDaysList.map(y=>{
       this.allowedDaysList.map(x=>{
        if(x.id==y){
          allowedDaysDataHolder.push(x);
        }
      })
    })
    };
     this.editform = this._fb.group({
      discountName: [{value: this.couponData.discountName, disabled: this.isViewOnly}, [Validators.required,Validators.maxLength(100)]],
      // appNotificationText: [{value:this.selectedAppNotificationText, disabled: this.isViewOnly}, Validators.required],
      appNotificationText: [{value:this.salesChannelList.filter(x => x.id == this.couponData.appNotificationText)[0], disabled: this.isViewOnly}, Validators.required],
      customerType: [4],
      discountValueType: [{value: this.discountTypeList.filter(x => x.id == this.couponData.discountValueType)[0], disabled: this.isViewOnly}, Validators.required],
      discountValue: [{value: this.couponData.discountValue, disabled: this.isViewOnly},Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)],
      maximumAllowed: [{value: this.couponData.maximumAllowed, disabled: this.isViewOnly},[Validators.required,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      maxCouponUse: [{value: this.couponData.maxCouponUse, disabled: this.isViewOnly},[Validators.max(999),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
      validity: [{value: this.validityList.filter(x => x.id == this.couponData.validity)[0], disabled: this.isViewOnly}, Validators.required],
      validityDate: [{value: [new Date(this.couponData.validityStartDate),new Date(this.couponData.validityEndDate)], disabled: this.isViewOnly}],
      allowedDaysList: [{value: allowedDaysDataHolder, disabled: this.isViewOnly}, Validators.required],
      allowedLocationsList: [{value: this.selectedSubscriptionPlanList, disabled: this.isViewOnly},Validators.required],
      isActive: [{value: this.statusList.filter(x => x.id == this.couponData.isActive)[0], disabled: this.isViewOnly},Validators.required], 
      applyOn: [{value: this.appliedOn.filter(x => x.id == this.couponData.applyOn), disabled: this.isViewOnly},Validators.required], 
      category: [{value: this.statusList.filter(x => x.id == this.couponData.category), disabled: this.isViewOnly},Validators.required], 
      couponCodesList: [{value: this.couponData.couponCodesList, disabled: this.isViewOnly},Validators.required],
      printText: [{value: this.couponData.printText, disabled: this.isViewOnly},Validators.required],
     })
  }

  setCustomValidator(){
    this.validation(this.f.discountValueType.value.id)
     this.f.discountValueType.valueChanges.subscribe(val=>{
      this.validation(val.id)
      })
    this.validationApplyOn(this.f.applyOn.value.id)
    this.f.applyOn.valueChanges.subscribe(val => this.validationApplyOn(val) ) 
    this.f.validity.valueChanges.subscribe(discountValue=>{
      if(discountValue.viewValue == 'Valid between date range'){
        this.f.validityDate.setValidators([Validators.required]);
      }else{
        this.f.validityDate.clearAsyncValidators();
      }
      this.f.validityDate.updateValueAndValidity();
    })
    this.f.couponCodesList.valueChanges.subscribe((v)=>{
      this.getURL()
    })
    this.f.printText.valueChanges.subscribe((v)=>{
      this.getURL()
    })
    this.getURL()
  }
  validationApplyOn(id:any) {
    if(id == 3){
      this.f.category.setValidators([Validators.required]);
    }else{
      this.f.category.clearAsyncValidators();
    }
    this.f.category.updateValueAndValidity();
  }
  getURL(){
    let url = this.f.printText.value
   if(url.includes('?')){
    this.genratedLink = url +'&trackingId='+this.f.couponCodesList.value 
   }else{
    this.genratedLink = url +'?trackingId='+this.f.couponCodesList.value 
   }
  }
   validation(val?:any){
    if(val === 0){
      // Flat
      this.maxValue = 999999;
      this.f.discountValue.setValidators([Validators.required,Validators.min(0),Validators.max(this.maxValue),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),this.maxFractionalDigitsValidator(2)]);
      this.f.maximumAllowed.clearValidators();
    }else if(val === 1){
      // Percent
      this.maxValue = 100;
      this.f.discountValue.setValidators([Validators.required,Validators.min(0),Validators.max(this.maxValue),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),this.maxFractionalDigitsValidator(2)]);
      this.f.maximumAllowed.setValidators([Validators.required,Validators.min(0),Validators.max(999999),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/),this.maxFractionalDigitsValidator(2)]);
    } else{
      // Tracking only
      this.maxValue = 0;
      this.f.discountValue.clearValidators();
      this.f.maximumAllowed.clearValidators();
      this.f.maxCouponUse.clearValidators();
      this.f.validity.clearValidators();
      this.f.validityDate.clearValidators();
      this.f.allowedDaysList.clearValidators();
      this.f.allowedLocationsList.clearValidators();
      this.f.applyOn.clearValidators();
      this.f.category.clearValidators();
    }
    this.f.discountValue.updateValueAndValidity();
    this.f.maximumAllowed.updateValueAndValidity();
    this.f.maxCouponUse.updateValueAndValidity();
    this.f.validity.updateValueAndValidity();
    this.f.validityDate.updateValueAndValidity();
    this.f.allowedDaysList.updateValueAndValidity();
    this.f.allowedLocationsList.updateValueAndValidity();
    this.f.applyOn.updateValueAndValidity();
    this.f.category.updateValueAndValidity();
   }
   maxFractionalDigitsValidator(maxDigits: number) {
    return (control: FormControl) => {
      if (!control.value || isNaN(control.value)) {
        return null;
      }

      const valueString = control.value.toString();
      const decimalIndex = valueString.indexOf('.');
      if (decimalIndex !== -1 && valueString.length - decimalIndex - 1 > maxDigits) {
        return { maxFractionalDigits: true }; // Validation failed
      }
      return null; // Validation passed
    };
  }
  searchButtonClick(e) {
    // if (this._couponCodeService.isViewOnly) return
    if (!this.searchText.length) return
    if (this.searchText == this.lastSearchText) {
      this.op.toggle(e)
      return
    }

    this.isLoading = true
    this.isListLoading = true
    this.performSearch(e)
  }
  performSearch(e) {
    let params = new HttpParams()
    params = params.append('startDate', '1800-01-01');
    params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    params = params.append('pageNo', 0);
    params = params.append('pageSize', 99999);
    params = params.append('filterValue', this.searchText)

    this._spinner.show()
    this._productService.getAllProductList(params).subscribe(res => {
      if (res.success) {
        this._spinner.hide()

        this.isLoading = false
        this.isListLoading = false
        this.searchList = res.data
        this.lastSearchText = this.searchText

        if (this.searchList.length == 1) {
          this.insertRow(this.searchList[0])
          this.op.hide()
          return
        } else
          if (this.searchList.length > 1) this.op.toggle(e)
          else {
            this._messageService.add({ severity: 'error', summary: 'No Product found with this search filter' })
            this.op.hide()
          }
      }
    })
  }
  onRowSelect(e) {
    this.op.hide()
    this.insertRow(e.data)
  }
  insertRow(e) {
    if(this.isProduct && !this.isNew){
      this.dataList = []
    }
    let data = JSON.parse(JSON.stringify(e))
    data.expiryDate = new Date()
    const found = this.dataList.find(f => f.productId == data.productId)
    if (found) {
      this._messageService.add({ severity: 'error', summary: 'Product already selected ', detail: data.itemName })
      return
    }
    let uom = this._abd.uom.find(f => f.id == data.uomId)
    data.uomId = uom.id
    data.productName = data.itemName
    data.description = data.itemName
    data.itemSKUId = data.itemSkuId
    data.selected = false

    this.op.hide()

    this.dataList.push(data)
  }
  removeItem(value){
    this.dataList = this.dataList.filter((v)=>v.productId !== value.productId)
  }
  saveFn(){
   
    if(this.f.discountValueType.value === 2 && this.f.applyOn.value === 2 && this.dataList.length < 1){
      this._connMessageService.showToastMessage(`Add product to the list`, 'error');
    }
   
    this._abd.showSpinner();
    // let couponLink = this.genratedLink+this.f.couponCodesList.value
    let couponLink = this.genratedLink
    const formData: any = {
      discountName: this.f.discountName.value,
      appNotificationText:  this.f.appNotificationText.value.id,
      customerType: 4,
      discountValueType: this.f.discountValueType.value.id,
      maxCouponUse: this.f.maxCouponUse.value || 0,
      validity: this.f.validity.value.id,
      allowedDaysList: (this.f.allowedDaysList.value.map(item => item.id)),
      allowedLocationsList: (this.f.allowedLocationsList.value.map(item => item.id)),
      isActive: this.f.isActive.value.id,
      couponCodesList: this.f.couponCodesList.value,
      PrintText: couponLink
      }
        
    if(formData.discountValueType === 0){
      // Flat
      formData.discountValue = this.f.discountValue.value || 0;
    }else if(formData.discountValueType === 1){
    //  Percentage
      formData.discountValue = this.f.discountValue.value || 0;
      formData.maximumAllowed = this.f.maximumAllowed.value || 0;
    }else{
      // Tracking Only
      formData.discountValue = 0;
      formData.maxCouponUse = 0;
      formData.validity = 0;
      formData.allowedDaysList = [0,1,2,3,4,5,6];
      formData.allowedLocationsList = this.allowedLocationsList.map(item => item.id)
    }
    if(formData.validity === 1){
      formData.validityStartDate = formatDate(this.f.validityDate.value[0] || new Date(), 'MM/dd/yyyy', 'en-US');
      formData.validityEndDate = formatDate(this.f.validityDate.value[1] || '2050-01-01', 'MM/dd/yyyy', 'en-US');
    }

    // //console.log(dummy)

    if(this.isNew){
      this._couponCodeService.addCoupon(formData).subscribe(res => {
        if(res.success){
          this.cancelButtonClick();
          this.onSubmit.emit(true)
          this._connMessageService.showToastMessage('Coupon added successfully', 'success');
        } 
        else {
          this._connMessageService.showToastMessage(`Coupon could not be added.`, 'error');
          this._abd.hideSpinner()
        }
      },(error)=>{
        this._abd.hideSpinner()
      })
    }   
    else{
      this._couponCodeService.updateCoupon(this._couponCodeService.activeItem?.id,formData).subscribe(res => {
        if(res.success){
          this.cancelButtonClick();
          this.onSubmit.emit(true)
          this._connMessageService.showToastMessage('Coupon updated successfully', 'success');
        } 
        else {
          this._connMessageService.showToastMessage(`Coupon could not be updated.`, 'error');
          this._abd.hideSpinner()
        }
      },(error)=>{
        this._abd.hideSpinner()
      })
    }
  }

  cancelButtonClick(){
    this._couponCodeService.showEditCoupon = false;
    this._couponCodeService.showNewEditCoupon = false;
  }
  validateKey(e: any){
    let specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if(this.allowedCouponsDummy.includes(e.value) || specialCharRegex.test(e.value) || e.value.length > 10 || e.value.length < 4 ){
      this.f.couponCodesList.setValue([])
    }
    else{
      this.f.couponCodesList.setValue(e.map((item)=>item.toUpperCase()))
    } }
} 