<!-- Upgrade Existing Plans -->
<p-dialog header="Confirmation" [(visible)]="ShowCustomConfirmation" [modal]="true" [draggable]="false"
[resizable]="false">
<div *ngIf="upgradeSucessMessage.length==0" class="plan-upgrade-message">
    <strong>You have chosen to {{ CurrentAction==1 ? 'upgrade' : 'downgrade' }} your plan.</strong>
    <br><br>
    <table class="p-datatable-table ng-star-inserted upgrade-plan">
        <tr class="ng-star-inserted">
            <td>From: <strong>{{ planData?.planName }} plan</strong></td>
            <td><strong>${{ currentSubscriptionCharges }}</strong> 
            {{(planData?.subscriptionInterval === 12 ? '/ Year ' : '/ Month') + '(Subscription + Tax)' }} 
          </td>
        </tr>
        <tr class="ng-star-inserted">
            <td>To: <strong>{{ this._signup.selectedPlanDetails?.title }} plan</strong></td>
            <td><strong>${{ newSubscriptionCharges }}</strong> 
                          {{(planData?.subscriptionInterval === 12 ? '/ Year': '/ Month') + '(Subscription + Tax)' }} 
                </td>
        </tr>
    </table>
    <ng-container *ngIf="subscriptionChangeNotes.length>0">

        <p-messages severity="info">
            <ng-template pTemplate>

                <div class="ml-2">{{subscriptionChangeNotes}}</div>
            </ng-template>
        </p-messages>
    </ng-container>
    Would you like to proceed?
</div>
<div *ngIf="upgradeSucessMessage.length>0" class="plan-upgrade-message">
    <p>You have sucessfully updated your plan</p>
</div>

<div class="flex flex-wrap align-items-center justify-content-center m-2">
    <div class="flex align-items-center justify-content-center m-2">
        <button pButton type="button" label="Confirm" *ngIf="upgradeSucessMessage.length==0" class="ml-2"
            style="width: 160px" (click)="performAction()"></button>
        <button pButton type="button" label="Ok" class="ml-2" style="width: 160px"
            (click)="ShowCustomConfirmation=false" *ngIf="upgradeSucessMessage.length>0"></button>
        <button pButton type="button" label="Cancel" *ngIf="upgradeSucessMessage.length==0" class="ml-2"
            style="width: 160px" (click)="ShowCustomConfirmation=false"></button>

    </div>
</div>

<div style="color:red" *ngIf="ErrorMessage.length>0">
    <br>
    <br>
    {{ErrorMessage}}
</div>
</p-dialog>
  <!-- /Upgrade Existing Plans  -->
   
<div class="billing-container">
  <div class="billing-header">
    <h1>Billing & Plans</h1>
    <p>Manage your subscription plan and billing details.</p>
  </div>
  <div class="billing-card">
    <div class="billing-card-header">
      <h2 class="p-0 m-0">{{planData?.planName}}</h2>
      <span class="current-plan-badge">Current Plan</span>
    </div>
    <p class="trial-info p-0 m-0" *ngIf="currentPlanDetails?.isFreeTrial">Ending in {{daysLeft()}} days. Upgrade your plan before it ends.</p>
    <p class="trial-info p-0 m-0" *ngIf="!currentPlanDetails?.isFreeTrial">Next Payment: {{currentPlanDetails?.subscription?.totalCost | currency }} on {{currentPlanDetails?.subscription?.nextChargeDate | date : "MMMM dd,yyyy"}} .</p>
    <p class="plan-description pt-2" *ngIf="currentPlanDetails?.isFreeTrial">Choose one of our paid plans below that will help you manage your store, attract more customers, and increase your sales.</p>
    <div class="subscription-details">
       
        <p *ngIf="currentPlanDetails?.isFreeTrial"><span class="subscription" >Subscribed on:</span>  <span>{{currentPlanDetails?.subscription?.freeTrialStartDate  | date : "MMMM dd,yyyy"}}</span></p>
        <p *ngIf="currentPlanDetails?.isFreeTrial"> <span class="subscription"  >Ending date:</span> <span>{{currentPlanDetails?.subscription?.freeTrialEndDate  | date : "MMMM dd,yyyy" }}</span></p>
        <p *ngIf="!currentPlanDetails?.isFreeTrial"><span class="subscription"  >Subscribed on:</span>  <span>{{currentPlanDetails?.subscription?.startDate  | date : "MMMM dd,yyyy"}}</span></p>
      <!-- <p *ngIf="!currentPlanDetails?.isFreeTrial"> <span class="subscription" >Amount charge:</span> <span>79.99/month billed annually or $959.88/year</span></p> -->
        <p *ngIf="!currentPlanDetails?.isFreeTrial"> <span class="subscription" >Subscription:</span> <span>{{(planData?.subscriptionInterval === 12 ? 'Yearly': 'Monthly')}}</span></p>
        <!-- <p *ngIf="!currentPlanDetails?.isFreeTrial"> <span class="subscription" >Auto renewal:</span> <span> <p-inputSwitch  ></p-inputSwitch> </span></p>  -->
    </div>
  </div>

<!-- Second Part -->

<div class="plans-container">
  <h1>Upgrade your plan today</h1>
  <div class="billing-options">
    <span class="billing-options-btns" *ngIf="currentPlanDetails?.isFreeTrial">
      <button [ngClass]="{'active': !isMonthly}" (click)="currentInterval(false)" >Annually (saves up to 20%)</button>
      <button [ngClass]="{'active': isMonthly}" (click)="currentInterval(true)" >Monthly</button>
    </span>
  </div>
  <div class="plans">
    <div class="plan-card recommended" *ngFor="let plan of plans;let index = i">
      <div class="plan-header">
        <!-- <span class="recommended-badge" *ngIf="plan?.title === 'Webstore'">Recommended</span> -->
         <h2>{{plan.title}}</h2>
      </div>
      <div class="plan-price">
        <span>{{plan?.prices?.value | currency }}</span>/month
      </div>
      <div class="plan-billing">
        {{(plan?.prices?.priceLimitTypeString === 'BilledAnnually' ? 'billed annually (USD)' : 'billed monthly (USD)')}}
      </div>
      <button *ngIf="!plan?.selected" (click)="goToCheckOut(plan)" >{{plan.button}}</button>
      <button *ngIf="plan?.selected" class="active-plan-btn" >{{plan.button }}</button>
      <div class="plan-features cursor-pointer" (click)="goToPricing()">
        See all features <img class="px-2" src="assets/images/see_all_features.svg">
      </div>
    </div>
  </div>
</div>

<div class="billing-history-container">
  <h2>Billing History</h2>
  <div class="billing-history-table">
      <div class="table-header">
          <div>Invoice#</div>
          <div>Date</div>
          <div>Plan</div>
          <div>Recurrence</div>
          <div>Charge</div>
          <div>Next Payment</div>
          <div>Status</div>
      </div>
      <div class="table-body">
          <div *ngFor="let invoice of invoices" class="table-row">
              <div>{{ invoice.invoiceNumber }}</div>
              <div>{{ invoice.date | date }}</div>
              <div>{{ invoice.plan }}</div>
              <div>{{  getRecurrence(invoice.recurrence)  }}</div>
              <div>{{ invoice.charge }}</div>
              <div>{{ invoice.nextPayment  }}</div>
              <div [ngClass]="invoice.status.toLowerCase()">{{ invoice.status }}</div>
          </div>
      </div>
  </div>
</div>



</div>