import { UserService } from 'src/app/_services/user.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { AfterViewChecked, AfterViewInit, Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { usTimeZoneList } from 'src/app/_data/time-zones';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-theme-overview',
  templateUrl: './theme-overview.component.html',
  styleUrls: ['./theme-overview.component.scss']
})
export class ThemeOverviewComponent implements OnInit,AfterViewInit   {
  _global = GlobalService;
  @ViewChild('iframe') iframeElement!: ElementRef<HTMLIFrameElement>;;
  isLoaded:boolean = true; 
  webUrl:SafeResourceUrl;

  constructor(
    public _abd: AppBaseDataService,
    public _businessSetup: BusinessSetupService,
    public _userService: UserService,
    private _router: Router,
    private _connMessageService: ConnMessageService,
    private sanitizer: DomSanitizer
  ){}

  ngAfterViewInit() {
    // const iframeWindow = this.iframeElement.nativeElement.contentWindow;
    // if (iframeWindow) {
    //   iframeWindow.postMessage('Hello iframe', 'https://development.lincsell.com');
    // }
  }

  getUrl(url:any){
    if(this._global.whiteLabel.wlName === 'LincSell'){
    if(url){
        return url.replace('https://', '');
    }else{ 
        return "";
    }}
}
openPreview(){
  if (Array.isArray(this._global.webStoreConfiguration)) {
    let iswebStoreSelectedDefault:boolean = false
iswebStoreSelectedDefault = this._global.webStoreConfiguration.filter(v => v.isDefault === true)?.length > 0 ? true : false  
if(this._global.webStoreConfiguration.length > 0 && !iswebStoreSelectedDefault){
         this._router.navigate(["select-template"])
}
} else if (typeof this._global.webStoreConfiguration === 'object' && this._global.webStoreConfiguration !== null) {
  
  window.open(this._global.webStoreConfiguration?.webStoreHost+'/preview/?page_key=home','_blank')
}
}
goToSite(){
  if(this._global.whiteLabel.wlName === 'LincSell'){
      if(this._global.webStoreConfiguration !== null){
          window.open(this._global.webStoreConfiguration?.webStoreHost,'_blank')
      }
  }
}
goToEditer(){
  if (Array.isArray(this._global.webStoreConfiguration)) {
    let iswebStoreSelectedDefault:boolean = false
iswebStoreSelectedDefault = this._global.webStoreConfiguration.filter(v => v.isDefault === true)?.length > 0 ? true : false  
if(this._global.webStoreConfiguration.length > 0 && !iswebStoreSelectedDefault){
         this._router.navigate(["select-template"])
}
} else if (typeof this._global.webStoreConfiguration === 'object' && this._global.webStoreConfiguration !== null) {
  
  window.open(this._global.webStoreConfiguration.demoUrl,'_blank')
}
}
  ngOnInit(): void {}

  checkIsURL(){
    if (Array.isArray(this._global.webStoreConfiguration)) {
     return false
    }else{
      return true
    }
   }


}
