import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { VendorsService } from 'src/app/_services/vendors.service';
import { DepartmentService } from 'src/app/_services/department.service';
import { Router } from '@angular/router';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { BehaviorSubject, debounceTime, Observable, Subject, Subscription } from 'rxjs';
import { CategoryService } from 'src/app/_services/category.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { ManufacturersService } from 'src/app/_services/manufacturers.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { MenuItem, MessageService } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { interval } from 'rxjs';
import { DiscountsService } from 'src/app/_services/discounts.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';
import { SalesChannelService } from 'src/app/_business-dashboard/_services/bizz-sales-channel.services';
import { CouponCodeService } from 'src/app/_business-dashboard/_services/bizz-coupon-codes.services';
import { DiscountTypeList } from 'src/app/_models/coupon-management';
import { BarcodeLabelPrintingService } from 'src/app/_services/barcode-label-printing.service';
import { WorldTimeZone } from 'src/app/_data/time-zones';
import { AuditTrialService } from 'src/app/_services/audit-trial.service';
import { CustomerSalesChannelService } from 'src/app/_services/sales-channel.services';
import { CustomerCouponCodeService } from 'src/app/_services/coupon-codes.services';

@Component({
  selector: 'app-report-top-filter-bar',
  templateUrl: './report-top-filter-bar.component.html',
  styleUrls: ['./report-top-filter-bar.component.scss']
})
export class ReportTopFilterBarComponent implements OnInit {
  searchTextLabel = 'Enter Title, SKU or Barcode'

  // searchText = ''
  // date: any = null
  // dateRange: any = null
  // locations: any = null
  // departments: any = null
  // selectedLocation: any = null
  selectedDepartment: any = null
  dateRange: any = ''
  showProductTypeDropDown = false;
  showImport = true
  showDisplayFilterPanel = true
  showSearchText = false
  showSearchButton = true
  showLocationsDropdown = false
//  Auto PO
  showVendorsDropdown = false
  showAddAutoPOBtn = false;
  // Auto PO
  showProductSearchTextInput = false;
  showAddNewProductLabel = false;
  showAddNewProductLabelMessage;
  showAddNewCustomProductLabel = false;
  showAddNewCustomProductLabelMessage;
  showReloadProductLabel = false;
  showReloadProductLabelMessage: string;
  locationsDropdownMessage = 'Locations'
  canClearShowlocationsDropdown = true
  showLocationsMultiselect = false
  showDefaultLocation = false
  showSingleDate = false
  showDateRange = false
  showTimeZone = false;
  showDepartmentDropdown = false
  showCatsDropdown = false
  showGroupByDropdown = false
  showAddNew = false
  showAddNewMessage = 'Add New'
  displayFilterPanel = false
  showExportOption = true;
  showTopFilterbar = true;
  showDirectReceive = false
  showSelectedInvAdj = false
  showSalesChannelDropdown = false;
  showSingleDateForReconcile = false
  showActivityType: boolean = false;
  showOperationType: boolean = false;
  salesChannelList:any[] = [];
// Coupon Code 
  showCouponCodeDiscountTypes =false; 
  couponDiscountTypeList = DiscountTypeList;

  lastSearchText = ''

  colheader: string[][] = [];
  colflds: string[] = [];

  baseFilterOptions: any = ''

  editform: FormGroup

  @Input() paraReportName: any = ''
  @Input() statusList: any = []
  @Input() disableAllControls = false
  @Output() filterCallbackFunc = new EventEmitter<any>()
  @Output() filterCallbackFuncUpdateFilter = new EventEmitter<any>()
  @Output() locationChangedEmitter = new EventEmitter<any>()
  @Output() exportExcelFn = new EventEmitter<any>()
  @Output() exportPdfFn = new EventEmitter<any>()
  @Output() exportCsvFn = new EventEmitter<any>()
  @Output() deleteItemFn = new EventEmitter<any>()
  @Output() printLabelFn = new EventEmitter<any>()
  @Output() reloadFn = new EventEmitter<any>()
  @Output() addNewFn = new EventEmitter<any>()
  @ViewChild("cm") cm: Menu
  @ViewChild('op') model
  
  _globals = GlobalService
  
  
  private modelChanged: Subject<string> = new Subject<string>()
  private subscription: Subscription;
  debounceTime = 3000;
  interval: any = ''
  timeZoneList = WorldTimeZone
  smallScreenDetected: boolean;
  isReport: boolean = false;
  permissions:any[]=[]
  
  items: MenuItem[] = [
    { id: '1', label: 'Last 7 days', command: (event) => { this.dateMenuOption(event) } },
    { id: '2', label: 'Last 15 days', command: (event) => { this.dateMenuOption(event) } },
    { id: '3', label: 'Current Month', command: (event) => { this.dateMenuOption(event) } },
    { id: '4', label: 'Last Month', command: (event) => { this.dateMenuOption(event) } },
    { id: '5', label: 'Current Year', command: (event) => { this.dateMenuOption(event) } },
    { id: '6', label: 'Last Year', command: (event) => { this.dateMenuOption(event) } },
  ]
  
  constructor(
    public _customerService: CustomersService,
    public _discountsService: DiscountsService,
    public _salesChannelService: SalesChannelService,
    public _couponCodeService: CouponCodeService,
    public _customerSalesChannelService: CustomerSalesChannelService,
    public _customerCouponCodeService: CustomerCouponCodeService,
    private _fb: FormBuilder,
    public _filterService: FilterServiceService,
    public _RS: ReportsService,
    public _abd: AppBaseDataService,
    private _router: Router,
    private _productService: ProductsService,
    public _departmentService: DepartmentService,
    public _vendorService: VendorsService,
    public _manufacturerService: ManufacturersService,
    public _categoryService: CategoryService,
    public _locationService: LocationsService,
    private _connMessageService: ConnMessageService,
    public _userService: UserService,
    public _barcodeLabelServices:BarcodeLabelPrintingService,
    private _messageService: MessageService,
    public _purchaseService: PurchaseService,
    public _rewardProgramService: RewardProgramService,
    public _auditTrialService:AuditTrialService,
  ) { }

  ngAfterContentInit() {
    let line = localStorage.getItem('filter')
    if (line) {
      let yy = JSON.parse(line)
      this._filterService.searchText = yy.searchText
    }
  }

  onSearchTextChanged() {

    // this._filterService.searchText = this.editform.get('searchText').value
    clearTimeout(this.timeout);

    // if (this.paraReportName == 'products')
    //   this._abd.productsearchText = this._filterService.searchText
    // if (this.paraReportName == 'inventory-report')
    //   this._filterService.OverViewproductsearchText = this._filterService.searchText
    if (this.paraReportName !== 'transfer-list') this.startTimer()
  }

  initForm() {
    this.editform = this._fb.group({
      searchText: [this._abd.productsearchText]
    })
  }
  timeout: any;
  startTimer() {
    this.disableAutoSearch = false;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (!this.disableAutoSearch)
        this.triggerSearchText();

    }, 3000);

  }

  // stopTimer() {
  //    this.interval.unsubscribe()
  // }

  disableAutoSearch = false;
  triggerSearchText() {
    clearTimeout(this.timeout);
    this.disableAutoSearch = true;
    //this._filterService.searchText = this.editform.get('searchText').value
    this.filterCallbackFuncUpdateFilter.emit()
  }

  ngOnInit(): void {
    //this._abd.resetSelectVariables()
    this.smallScreenDetected = window.innerWidth <= 1441;
    this.initForm()
    this._filterService.searchText = ""
    this._filterService.selectedCategory = null
    if (!this._filterService.dateRange[0]) this._filterService.initDates()
    this.getCashiers()
    let self = this;
    this._abd.reports.map(x => {
      if (x.paraReportName === this.paraReportName) {
        this.showGroupByDropdown = x.reportTopFilterBar.showGroupByDropdown
        this.showDepartmentDropdown = x.reportTopFilterBar.showDepartmentDropdown
        this.searchTextLabel = x.reportTopFilterBar.searchTextLabel
        this.showSearchText = x.reportTopFilterBar.showSearchText
        this.showLocationsDropdown = x.reportTopFilterBar.showLocationsDropdown
        this.showLocationsMultiselect = x.reportTopFilterBar.showLocationsMultiselect
        this.showSingleDate = x.reportTopFilterBar.showSingleDate
        this.showDateRange = x.reportTopFilterBar.showDateRange && !this.showSingleDate
        this.showExportOption = x.reportTopFilterBar.showExportOption || true;
        this.isReport = true
        if (x.groupBy != undefined && x.groupBy.length > 0) {
          self._abd.setGroupByOptions(x.groupBy)
        }
      }
      this.baseFilterOptions = this._abd.appCookedData(false)
    })

    if (this.paraReportName === 'inventory-report') {
      this.showSearchText = true,
      // this.showProductSearchTextInput=false;
      //this.showProductTypeDropDown = true && !this.smallScreenDetected;
      this.showLocationsDropdown = true && !this.smallScreenDetected && this._abd.initActLocations().length > 1
      //this.showLocationsMultiselect = !this.showLocationsDropdown && !this.smallScreenDetected
      this.showSingleDate = false
      this.showDateRange = true && !this.smallScreenDetected
      this.baseFilterOptions = this._abd.appCookedData(true)
      this.showExportOption = true;
      this.isReport = true
      this.canClearShowlocationsDropdown = false
    } else if (this.paraReportName === 'products') {
      this.showSearchText = true
      this.showProductSearchTextInput = false;
      this.showProductTypeDropDown = true;
      // this.showProductSearchTextInput=true;
      // this.showDateRange = true
      this.showAddNew = true
      this.showAddNewMessage = 'Add new product'
      this.isReport = true
    }else if (this.paraReportName === 'barcode-label') {
      this.showImport = false
     if(this._abd.initActLocations()?.length > 1)
     {
      this.showSearchButton = true
    }else{
      this.showSearchButton = false
    }
      this.showLocationsMultiselect = true;
      this.showExportOption = false
      this.showDisplayFilterPanel = false
      this.showSearchText = false
      this.searchTextLabel = 'Enter Name/SKU/Barcode'
      this.showProductSearchTextInput = false;
      this.showAddNew = true
      this.showAddNewMessage  = 'Add Product Label'
      this.showAddNewCustomProductLabel = true;
      this.showReloadProductLabel = true;
      this.showAddNewCustomProductLabelMessage = 'Add Custom Label'
      this.showReloadProductLabelMessage = 'Reload'
    } else if (this.paraReportName === 'departments') {
      this.showImport = false
      this.showDisplayFilterPanel = false
      this._departmentService.activeItem.departmentName = ''
      this.searchTextLabel = 'Enter department name'
      this.showSearchText = true
      this.showAddNew = true
      this.showAddNewMessage = 'Add new department'
      this.isReport = true
    } else if (this.paraReportName === 'category') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.searchTextLabel = 'Enter Category'
      this.showSearchText = true
      this.showAddNew = true
      this.showAddNewMessage = 'Add new category'
      // this._categoryService.activeItem.categoryName = ''
      this.isReport = true
    } else if (this.paraReportName === 'manufacturer') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.searchTextLabel = 'Enter manufacturer name'
      this.showSearchText = true
      this.showAddNew = true
      this.showAddNewMessage = 'Add new manufacturer'
      this.isReport = true
    }
     else if (this.paraReportName === 'time-attendance') {
     this.showTimeZone = true
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.searchTextLabel = 'Enter User name'
      this.showExportOption = false
      this.showSearchText = true
      this.showAddNew = false
      this.showAddNewMessage = 'Add new vendor'
      this.isReport = true
    }
     else if (this.paraReportName === 'vendors') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.searchTextLabel = 'Enter vendor name'
      this.showSearchText = true
      this.showAddNew = true
      this.showAddNewMessage = 'Add new vendor'
      this.isReport = true
    } else if (this.paraReportName === 'inventory-count') {
      this.showImport = false
      this.showSearchText = true
      this.showProductSearchTextInput = false;
      this.showExportOption = false
      this.showProductTypeDropDown = true && !this.smallScreenDetected;
      this.showLocationsDropdown = true && !this.smallScreenDetected
      this.baseFilterOptions = this._abd.appCookedData(true)
      this.isReport = true
    } else if (this.paraReportName === 'sales-invoices') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showSearchText = true && !this.smallScreenDetected
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showProductTypeDropDown = true && !this.smallScreenDetected
      this.showDateRange = true
      this.isReport = true
      this.searchTextLabel = 'Enter Name/Invoice Number'
    } else if (this.paraReportName === 'locations-list') {
      this.permissions = ['2012']
      this.showDisplayFilterPanel = false
      this.showSearchButton = false
      this.showImport = false
      this.showSearchText = false
      this.showAddNew = true
      this.showAddNewMessage = 'Add new Location'
      this.searchTextLabel = "Enter location, phone"
      this.showImport = false
      this.isReport = true
      this.showExportOption = false;
    }
    else if (this.paraReportName === 'Customers') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true
      this.showAddNew = true
      this.showAddNewMessage = 'Add new Customer'
      this.searchTextLabel = "Enter Name, Phone"
      this.showImport = false
      this.isReport = true
      this.showExportOption = true;
    } else if (this.paraReportName === 'Connect Customers') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = false
      this.showAddNew = false
      this.showAddNewMessage = 'Add new Location'
      this.searchTextLabel = "Enter Name, Phone"
      this.showImport = false
      this.isReport = true
      this.showSearchButton=false;
      this.showExportOption = false;
    }


    else if (this.paraReportName === 'customer-group') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true
      this.showAddNew = true
      this.searchTextLabel = "Enter Group Name"
      this.showImport = false
      this.isReport = true
      this.showExportOption = false;
      this.showAddNewMessage = 'Add New Group'
    }
    else if (this.paraReportName === 'users') {
      this.permissions = ['2007']
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = true
      this.showImport = false
      this.searchTextLabel = "Enter user name or email"
      this.isReport = true
      this.showExportOption = false;

    }
    else if (this.paraReportName === 'time-attendance') {
      // this.permissions = ['2007']
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = false
      this.showImport = false
      this.searchTextLabel = "Enter user name or email"
      this.isReport = false
      this.showExportOption = false;

    }
    else if (this.paraReportName === 'daily-sales-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true
      this.showProductTypeDropDown = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'tip-summary-report') {
      this.showDisplayFilterPanel = true
      this.showLocationsMultiselect = true
      this.showDateRange = true
      this.showExportOption = false;
       this.isReport = true
    }
    else if (this.paraReportName === 'tip-detail-report') {
      this.showDisplayFilterPanel = true
      this.showLocationsMultiselect = true
      this.showDateRange = true
      this.showExportOption = false;
       this.isReport = true
    }
    else if (this.paraReportName === 'time-tracking-summary-report') {
      this.showDisplayFilterPanel = true
      this.showLocationsMultiselect = false
      this.showDateRange = true
      this.showExportOption = true;
       this.isReport = true
       this.showTimeZone = true
    }
    else if (this.paraReportName === 'time-tracking-detail-report') {
      this.showDisplayFilterPanel = true
      this.showLocationsMultiselect = false
      this.showDateRange = true
      this.showExportOption = true;
       this.isReport = true
       this.showTimeZone = true
    }
    else if (this.paraReportName === 'daily-money-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true
      //this.showProductTypeDropDown = true && !this.smallScreenDetected
      this.isReport = true
      this.showExportOption = false;
    }

    else if (this.paraReportName === 'importinventory') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showLocationsMultiselect = false
      this.showDateRange = false
      this.showSearchText = false;
      this.showExportOption = false;
      this.showTopFilterbar = false;
      this.isReport = true
    }
    else if (this.paraReportName === 'hourly-sales-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showSingleDate = true;
      this.isReport = true;
      this.showDateRange = false
    }
    else if (this.paraReportName === 'tender-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      // this.showSingleDate = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'department-sales-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      this.showDepartmentDropdown = true && !this.smallScreenDetected;
      this.showGroupByDropdown = false
      this.isReport = true
    }
    else if (this.paraReportName === 'category-sales-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      this.showCatsDropdown = true && !this.smallScreenDetected;
      this.showGroupByDropdown = false
      //  this.showSingleDate = true
      this.isReport = true
    }
    else if (this.paraReportName === 'sales-tax-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true

      //  this.showSingleDate = true
      this.isReport = true
    }
    else if (this.paraReportName === 'credit-card-summary-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      //  this.showSingleDate = true
      this.isReport = true
    }
    else if (this.paraReportName === 'credit-card-sales-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showExportOption = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected;
      this.showDateRange = true && !this.smallScreenDetected;
      if (this.smallScreenDetected) this.showSearchButton = false
      this.showSingleDate = false
      this.isReport = true

    }
    else if (this.paraReportName === 'return-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true;
      this.showSingleDate = false
      this.isReport = true

    }
    else if (this.paraReportName === 'discount-sales-report') {
      this.showSearchText = true
      this.searchTextLabel = "Enter text to search"
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true;
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'loyalty-discount-report') {
      this.showSearchText = false
      this.searchTextLabel = "Enter text to search"
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true;
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'loyalty-customer-report') {
      this.showSearchText = false
      this.searchTextLabel = "Enter text to search"
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true;
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'tiered-reward-report') {
      this.showSearchText = true
      this.searchTextLabel = "Enter text to search"
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true;
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'tiered-reward-summary-report') {
      this.showSearchText = true
      this.searchTextLabel = "Enter text to search"
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true;
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'tiered-reward-product-report') {
      this.showSearchText = true
      this.searchTextLabel = "Enter text to search"
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true
      this.showDateRange = true;
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.isReport = true
    }
    else if (this.paraReportName === 'discounts') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = true
      this.searchTextLabel = "Enter discount name"
      this.isReport = true
      this.showExportOption = false;
      this.showDisplayFilterPanel = true
      this.showLocationsDropdown = true
      // this.showLocationsMultiselect = true && !this.smallScreenDetected
    }
    else if (this.paraReportName === 'sales-channel') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = true
      this.searchTextLabel = "Enter Channel Name"
      this.isReport = true
      this.showExportOption = true;
    }
    else if (this.paraReportName === 'customer-sales-channel') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = true
      this.searchTextLabel = "Enter Channel Name"
      this.isReport = true
      this.showExportOption = true;
    }
    else if (this.paraReportName === 'coupon-code') {
      this.showDisplayFilterPanel = false
      this.showCouponCodeDiscountTypes = true
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = true
      this.searchTextLabel = "Enter Coupon Name/Code"
      this.isReport = true
      this.showExportOption = true;
    }
    else if (this.paraReportName === 'customer-coupon-code') {
      this.showDisplayFilterPanel = false
      this.showCouponCodeDiscountTypes = true
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = true
      this.searchTextLabel = "Enter Coupon Name/Code"
      this.isReport = true
      this.showExportOption = true;
    }
    else if (this.paraReportName === 'sales-channel-report') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = false;
      this.showSalesChannelDropdown = true
     this.getSalesChannel()
      this.showDateRange = true
      this.showAddNew = false
      this.searchTextLabel = "Search by Channel Name"
      this.isReport = true
      this.showExportOption = true;
    }
    else if (this.paraReportName === 'coupon-summary') {
      // Coupon Report 
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showSearchText = true;
      this.showDateRange = true
      this.showAddNew = false
      this.searchTextLabel = "Enter Code/ Channel Name to search "
      this.isReport = true
      this.showExportOption = true;
    }
    else if (this.paraReportName === 'loyalty-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      this.showSingleDate = false;
      this.isReport = true;
    }
    else if (this.paraReportName === 'product-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      this.showSingleDate = false;
      this.isReport = true;
      this.showSearchText = true;
      this.searchTextLabel = "Enter SKU/Barcode/Name"
    }
    else if (this.paraReportName === 'gross-profit-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      this.showSingleDate = false;
      this.isReport = true;
    }
    else if (this.paraReportName === 'transfer-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      // this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      this.showSingleDate = false;
      this.isReport = true;
    }
    else if (this.paraReportName === 'transfer-detail-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      // this.showLocationsMultiselect = true && !this.smallScreenDetected
      this.showDateRange = true
      this.showSingleDate = false;
      this.isReport = true;
    }

    else if (this.paraReportName === 'transfer-detail-report2') {
      this.showDisplayFilterPanel = false
      this.showDateRange = false
      this.showSingleDate = false;
      this.isReport = true;
    }
    else if (this.paraReportName === 'transfer-list') {
      this.showSearchText = true;
      this.searchTextLabel = "Search by Transfer ID"
      this.showAddNew = true
      this.showImport = false
      this.isReport = true;
      this.showDefaultLocation = true
      // this.showLocationsDropdown = true
      // this.locationsDropdownMessage = 'Sender Location'
      // this.canClearShowlocationsDropdown=false
    }
    else if (this.paraReportName === 'transfer-receive-list') {
      this.showSearchText = true;
      this.searchTextLabel = "Search by Transfer ID"
      this.showAddNew = false
      this.showImport = false
      this.isReport = false;
      this.showDefaultLocation = true
    }
    else if (this.paraReportName === 'purchase-orders-list') {
      this.showSearchText = true;
      this.searchTextLabel = "Search by PO number"
      this.showAddNew = true
      this.showImport = false
      this.isReport = false;
      this.showDefaultLocation = true
      this.showDirectReceive = true
      this.showAddAutoPOBtn = true 
    }
    //   // Auto PO
    else if (this.paraReportName === 'auto-generate-purchase-order') {
      this.showSearchText = false;
      // this.showVendorsDropdown = true
      this.showImport = false
      this.isReport = false;
      this.showLocationsDropdown = true 
      this.showExportOption = false;
     this.showDisplayFilterPanel = false
     this.canClearShowlocationsDropdown = false
    }
    else if (this.paraReportName === 'purchase-orders-receive-list') {
      this.showSearchText = true;
      this.searchTextLabel = "Search by PO number"
      this.showAddNew = false
      this.showImport = false
      this.isReport = false;
      this.showDefaultLocation = true
    }
    else if (this.paraReportName === 'customer-group-list') {
      this.showLocationsMultiselect = false && !this.smallScreenDetected
      this.showSearchText = true;
      this.searchTextLabel = "Enter Name, Phone"
      this.showAddNew = false
      this.showImport = false
      this.isReport = false;
      this.showDefaultLocation = false;
      this.showExportOption = false;
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showDateRange = false
      this.showSingleDate = false;
      this.isReport = false;
    }
    else if (this.paraReportName === 'app-inventory-adjustment-list') {
      this.showImport = false
      this.showExportOption = false
      this.showSearchText = true;
      this.searchTextLabel = "Search By User Name"
      this.showAddNew = true
      this.showAddNewMessage = 'Add Adjustment'
      this.isReport = false;
      this.showDefaultLocation = true
      this._productService.isViewOnly = false
      this.showSelectedInvAdj = true
    }
    else if (this.paraReportName === 'app-inventory-adjustement-summary-report') {
      this.showImport = false
      this.showExportOption = true
      this.showSearchText = true;
      this.searchTextLabel = "Search By User Name"
      this.showAddNew = false
      this.showAddNewMessage = 'Add Adjustment'
      this.isReport = false;
      this.showDefaultLocation = true
      this.showSelectedInvAdj = true
    }
    else if (this.paraReportName === 'app-inventory-adjustment-detail') {
      this.showImport = false
      this.showExportOption = true
      this.showSearchText = true;
      this.searchTextLabel = "Search By SKU/Barcode/Name"
      this.showAddNew = false
      this.showAddNewMessage = 'Add Adjustment'
      this.isReport = false;
      this.showDefaultLocation = true
      this.showSelectedInvAdj = true
    }
    else if (this.paraReportName === 'cash-reconciliation-report') {
      // this.showSearchText = true
      this.showDateRange = true
      this.showLocationsDropdown = true
      this.canClearShowlocationsDropdown = false
      this.showDisplayFilterPanel = false
    }

    else if (this.paraReportName === 'cash-reconciliation-report-3') {
      // this.showSearchText = true
      this.showSingleDateForReconcile = true
      this.showLocationsDropdown = true
      this.canClearShowlocationsDropdown = false
      this.showDisplayFilterPanel = false
    }

    else if (this.paraReportName === 'cash-detail-report' || this.paraReportName === 'app-till-report') {
      this.showDateRange = true
      this.showLocationsMultiselect = true
      this.canClearShowlocationsDropdown = false
      this.showDisplayFilterPanel = false
        this.showExportOption = false
    }

    else if (this.paraReportName === 'app-cash-count-report') {
      this.showDateRange = true
      this.showLocationsMultiselect = true
      this.canClearShowlocationsDropdown = false
      this.showDisplayFilterPanel = false
    }

    else if (this.paraReportName === 'app-cash-drawer-report') {
      this.showDateRange = true
      this.showLocationsMultiselect = true
      this.canClearShowlocationsDropdown = false
      this.showDisplayFilterPanel = false
    }
    else if (this.paraReportName === 'reward-program-general') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.showAddNew = true
      this.searchTextLabel = "Enter perk name"
      this.isReport = true
      this.showExportOption = false;
      this.showDisplayFilterPanel = false
      this.showDefaultLocation = false;
      this.showLocationsMultiselect = false && !this.smallScreenDetected
    }
    else if (this.paraReportName === 'customer-purchase-summary-report') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.searchTextLabel = "Search by Customer Name/Phone/Email/Product Name/Barcode/SKU"
      this.isReport = true
      this.showExportOption = true;
      this.showDisplayFilterPanel = false,
      this.showDateRange = true
    }
    else if (this.paraReportName === 'customer-purchase-details-report') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = true;
      this.searchTextLabel = "Search by Customer Name"
      this.isReport = true
      this.showExportOption = true;
      this.showDisplayFilterPanel = false,
      this.showDateRange = true
    }
    else if (this.paraReportName === 'customer-group-report') {
      this.showDisplayFilterPanel = true
      this.showImport = false
      this.showSearchText = true;
      this.searchTextLabel = "Search by Customer Name"
      this.isReport = true
      this.showExportOption = true;
      this.showDateRange = true
    }
    else if (this.paraReportName === 'audit-trial') {
      this.showDisplayFilterPanel = false
      this.showImport = false
      this.showSearchText = false;
      this.showLocationsMultiselect = false;
      this.searchTextLabel = "Search by User"
      this.isReport = true
      this.showExportOption = false;
      this.showDateRange = true
      this.showActivityType = true
      this.showOperationType = true
    }
  }
  getCashiers(){
    const obj = {
      "Name": "GETALLEMPLOYEES",
      "filterValue":"",
      "PageNo":0,
      // "PageSize":,
      "User": {
        "ParentID": (this._globals.userInfo.parentID != '' ? this._globals.userInfo.userID : this._globals.userInfo.parentID),
        "CompanyName": this._globals.userInfo.businessSetup[0].businessName.toUpperCase(),
      },
    }
        this._userService.getAllUsers(obj).subscribe(res => {
          if (res.success) {
            // debugger;
           this._abd.cashiers = res.data
          }
        })
}

 getSalesChannel(){
  const obj = {
    "PageNo": 0,
    "PageSize": 999999,
  }
    this._salesChannelService.getAllSalesChannel(obj).subscribe(res => {
      if (res.success) {
        this.salesChannelList = res.data}
 })}
  // cash-reconciliation-report
  callbackFunc(what: string) {
    clearTimeout(this.timeout);
    if (!this._abd.dateRange[0]) this._abd.dateRange[0] = new Date()
    if (!this._abd.dateRange[1]) this._abd.dateRange[1] = this._abd.dateRange[0]

    const xx = this._abd.dateRange
    // this._filterService.searchText = this.editform.get('searchText').value
    this.filterCallbackFunc.emit(what)
  }

  filterButtonPressed() {
    //this.stopTimer()
    //clearTimeout(this.timeout);

    let x = this._filterService.searchText
    this.triggerSearchText();
    //this.filterCallbackFuncUpdateFilter.emit()
    this.displayFilterPanel = false
  }

  exportExcel() {
    this.exportExcelFn.emit()
  }

  exportPdf() {
    this.exportPdfFn.emit()
  }

  exportCsv() {
    this.exportCsvFn.emit()
  }

  deleteItem() {
    this.deleteItemFn.emit()
  }
  
  printLabel() {
    this.printLabelFn.emit()
  }

  addNewClick() {
    if (this.paraReportName === 'products') {
      this._productService.activeItem = null
      this._productService.isNew = true
      this._productService.isEdit = false
      this._productService.isViewOnly = false
      this._router.navigate(['app/new-product'])
    }
    else if (this.paraReportName === 'departments') {
      this._departmentService.activeItem.departmentName = ''
      // this._departmentService.showNewNewEdit = true
      this.addNewFn.emit('department')
    }
    else if (this.paraReportName === 'vendors') {
      this._vendorService.initActiveItem()
      // this._vendorService.showNewNewEdit = true
      this._vendorService.isViewOnly = false
      this._vendorService.isNewVendor = true
      this.addNewFn.emit('vendor')
    } else if (this.paraReportName === 'category') {
      this._categoryService.showNewNewEdit = true
    }
    else if (this.paraReportName === 'Customers') {
      this._customerService.showNewNewEdit = true;
      // this._customerService.showNewEdit = false;
      this.addNewFn.emit('customer')
    }
    else if (this.paraReportName === 'customer-group') {
      this._customerService.showNewNewGroupEdit = true;
      this._customerService.showNewEdit = false;
      this._customerService.groupInfo = null;
    }
    else if (this.paraReportName === 'manufacturer') {
      this._manufacturerService.showNewNewEdit = true
      this.addNewFn.emit('manufacturer')
    }
    else if (this.paraReportName === 'locations-list') {
      if (this._locationService.isLocationAvailable()) {
        this._locationService.blankActiveItem()
        this._locationService.clickEvent.next('')
        this._locationService.isNewLocation = true
      }
      else {
        this._connMessageService.showToastMessage('Location limit exceeded.', 'error');
      }
      this.showExportOption = false;
    } else if (this.paraReportName === 'users') {
      this.permissions = ['2007']
      this._userService.isNew = true
      this._router.navigate(['app/users-new-edit'])
    }
    else if (this.paraReportName === 'barcode-label') {
      this._barcodeLabelServices.blankActiveItem();
      this._barcodeLabelServices.showNewEditBarcodeLabel = true
    }
    else if (this.paraReportName === 'discounts') {
      this._discountsService.blankActiveItem();
      this._discountsService.showNewEditDiscount = true;
      // this._router.navigate(['app/users-new-edit'])
    }
    else if (this.paraReportName === 'sales-channel') {
      this._salesChannelService.blankActiveItem();
      this._salesChannelService.showNewEditChannel = true;
      // this._router.navigate(['app/users-new-edit'])
    }
    else if (this.paraReportName === 'customer-sales-channel') {
      this._customerSalesChannelService.blankActiveItem();
      this._customerSalesChannelService.showNewEditChannel = true;
      // this._router.navigate(['app/users-new-edit'])
    }
    else if (this.paraReportName === 'coupon-code') {
      this._couponCodeService.blankActiveItem();
      this._couponCodeService.showNewEditCoupon = true;
      // this._router.navigate(['app/users-new-edit'])
    }
    else if (this.paraReportName === 'customer-coupon-code') {
      this._customerCouponCodeService.blankActiveItem();
      this._customerCouponCodeService.showNewEditCoupon = true;
      // this._router.navigate(['app/users-new-edit'])
    }
    else if (this.paraReportName === 'transfer-list') {
      const locations = this._abd.initActLocations()
      if (!locations || locations.length < 2) {
        this._messageService.add({
          severity: 'error',
          summary: 'Error', detail: 'Must have atleast 2 locations to perform this action!'
        })
        return
      }

      this._productService.activeItem = null
      this._productService.isNew = true
      this._productService.isViewOnly = false
      this._productService.isEdit = false
      this._router.navigate(['app/transfer-out-form'])
    }
    else if (this.paraReportName === 'purchase-orders-list') {
      this._purchaseService.newEditItem.isNew = true
      this._purchaseService.newEditItem.isViewOnly = false
      this._purchaseService.newEditItem.isEdit = false

      this._router.navigate(['app/purchase-orders-create-edit'])
    }
    else if (this.paraReportName === 'app-inventory-adjustment-list') {
      this._purchaseService.newEditItem.isNew = true
      this._purchaseService.newEditItem.isViewOnly = false
      this._purchaseService.newEditItem.isEdit = false

      this._router.navigate(['app/app-inventory-adjustment'])
    }
    else if (this.paraReportName === 'app-inventory-adjustment-list') {
      this._purchaseService.newEditItem.isNew = true
      this._purchaseService.newEditItem.isViewOnly = false
      this._purchaseService.newEditItem.isEdit = false
    }
    else if (this.paraReportName === 'reward-program-general') {
      this._rewardProgramService.showNewEditTieredModal = true
    }
  }
  secondAddNewClick(){
      this._barcodeLabelServices.blankActiveItem();
      this._barcodeLabelServices.showNewEditCustomBarcodeLabel = true
  }
  reloadProductLabel(){
    this.reloadFn.emit()
  }

  importClick() {
    if (this.paraReportName === 'products') {
      this._productService.activeItem = null
      this._router.navigate(['app/importproducts'])
    }
  }
  // Auto PO
  openAutoPO(){
    this._purchaseService.isOpenAutoPO = true;
    this._filterService.selectedVendor = null
  }

  dateMenuOption(event) {
    const opt = event.item.id * 1
    const today = new Date()
    let sd: any = ''
    let ed: any = ''

    switch (opt) {
      case 1:
        this.dateRange = []
        sd = new Date(new Date().setDate(new Date().getDate() - 7))
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 2:
        this.dateRange = []
        sd = new Date(new Date().setDate(new Date().getDate() - 15))
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 3:
        this.dateRange = []
        sd = new Date(today.getFullYear(), today.getMonth(), 1)
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 4:
        this.dateRange = []
        sd = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        ed = new Date(today.getFullYear(), today.getMonth(), 0)
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 5:
        this.dateRange = []
        sd = new Date(today.getFullYear(), 0, 1)
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 6:
        this.dateRange = []
        sd = new Date(today.getFullYear() - 1, 0, 1)
        ed = new Date(today.getFullYear() - 1, 11, 31)
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
    }

    this._filterService.dateRange = this.dateRange
    this.cm.hide()
  }

  locationChanged(e) {
    this.locationChangedEmitter.emit(e.value)
  }

  newDirectPO() {
    this._purchaseService.isNewDirectPO = true
    this._purchaseService.newEditItem.isViewOnly = false
    this._router.navigate(['app/purchase-orders-receive-direct'])
  }

}
