import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, Input, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { WhiteLabelList } from 'src/app/_data/white-label-list';

@Component({
  selector: 'app-product-general-properties',
  templateUrl: './product-general-properties.component.html',
  styleUrls: ['./product-general-properties.component.scss']
})
export class ProductGeneralPropertiesComponent implements OnInit {
 @Input() viewOnly = false
  uom:any=[]
  constructor(
    public _productService: ProductsService,
    public _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    if(WhiteLabelList[0].wlsName === "lincsell"){
      this._productService.productInfo.sellAs = this._productService.productInfo.sellAs.filter((v)=>v.name !== "Weight")
    }
    const x = this._productService.selectedSellAs

    this.setUoms(x.value)
  }

  sellAsClicked(e) {
    this.setUoms(e.value.value)
  }

  setUoms(value:any) {
    const uom = JSON.parse(JSON.stringify(this._abd.uom))
    this._abd.uomCalc=[]
    this._abd.uomCalc = this._abd.uom.filter(f => f.unitClassId === value)
  }

}
