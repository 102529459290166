import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-loyalty-program-subscribe',
  templateUrl: './loyalty-program-subscribe.component.html',
  styleUrls: ['./loyalty-program-subscribe.component.scss']
})
export class LoyaltyProgramSubscribeComponent implements OnInit {

    _globals = GlobalService
  constructor() { }

  ngOnInit(): void {
  }

  subscribe(){
    try {
        if (this._globals.whiteLabel.id == 'E0D7F13C-7A78-40B7-8049-CF8A1F1C245F') {

            var anchor = document.createElement('a');
            anchor.href = 'https://www.connect-cbd.net#contact';
            anchor.target = "_blank";
            anchor.click();
        }
        else {
           location.href=location.origin+'#/app/billing-and-plans'
          //  location.href=location.origin+'#/app/subscription-management'
        }
    } catch (error) {

    }
  }

}
