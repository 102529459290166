import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { CustomerSalesChannelService } from 'src/app/_services/sales-channel.services';


@Component({
  selector: 'app-sales-channel-list',
  templateUrl: './sales-channel-list.component.html',
  styleUrls: ['./sales-channel-list.component.scss']
})
export class SalesChannelListComponent implements OnInit {

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  dataList: any = null
  searchText = ''
  lastSearchText = ''
  masterDataList: any = null
  isViewOnly = false
  
  orderParam = ''
  orderBy = 'asc'


  colHeader = [['Full Name ', 'Status']];

  colflds = ['fullName', 'status'];
   indexArray:number[] = [];
  actions: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-book' },
    { label: 'Delete', icon: 'pi pi-trash' },
  ]

  @ViewChild('p', { static: false }) paginator: Paginator;


  constructor(
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public salesChannelServices: CustomerSalesChannelService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
   ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Sales Channel'
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }
      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.filterValue) this._filterService.searchText = filter.filterValue
      } 
      clearInterval(ti)
      this.getDataList()
    }, 500);
  }

  getDataList() {
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    const obj = {
      "PageNo": this.page,
      "PageSize": this.rows,
      "filterValue": this._filterService.searchText,
      "orderParam":this.orderParam,
      "orderBy":this.orderBy,
    }

    this._abd.showSpinner()
    try {
      this.salesChannelServices.getAllSalesChannel(obj).subscribe(res => {
        if (res.success) {
          this.totalRecords = res.totalRecords
          let dataList = res.data
          let startFrom = this.page * this.rows+1
          this.dataList = dataList.map((value,index)=>{
            return{
              index:index+startFrom,...value
            }
          })
       
          this.masterDataList = this.dataList;
          this._abd.hideSpinner()
        }
      })

      this._filterService.updateFilters(this._globals.componentName, obj)
      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    } catch { 
      this._abd.hideSpinner()
     }
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  refrestDataList(e: any) {
    this.getDataList();
  }
  checkIfChannelUse(){
    this.optionDeleteClicked()
  //   this._abd.showSpinner()
  //   setTimeout(() => {
  //     this._abd.hideSpinner()
  //     this.optionDeleteClicked()
  //   }, 3000);
  //   let obj = {
  //     "channelName":this.salesChannelServices.activeItem.fullName,
  //   }
  //   this.couponCodeServices.getAllCouponCodes(obj).subscribe(res => {
  //     if (res.success) {


  //    }
  //  })
  }
  optionDeleteClicked() {
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this.salesChannelServices.deleteChannel(this.salesChannelServices.activeItem.id).subscribe(res => {
            if (res.success) {
              this.getDataList();
              this._connMessageService.showToastMessage(`Sales Channel deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }
  actionButtonClick(item: any) {
    this.salesChannelServices.activeItem = JSON.parse(JSON.stringify(item))
  }
  editButtonClicked() {
    this.isViewOnly = false
    this.salesChannelServices.showEditChannel = true;
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.getDataList()
  }

  onPageChange(e) {
    this.page = e.page
    this.rows = e.rows
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

  exportExcel() {
    this._abd.exportExcel(this.dataList, this.colHeader[0], this.colflds, 'Sales Channel',false)
  }

  exportPdf() {
    this._abd.exportPdf(this.dataList, this.colHeader, this.colflds, 'Sales Channel', false,false,false)
  }

  onRowSelect(e) {
    this.isViewOnly = true
    this.salesChannelServices.activeItem = JSON.parse(JSON.stringify(e.data))
    this.salesChannelServices.showEditChannel = true;
  } 
  customSort(e) {
    const last = this.orderParam
    switch (e.sortField) {
      case 'fullName':
        this.orderParam = 'fullName'
        break;
      case 'status':
        this.orderParam = 'status'
        break;
     
      default:
        this.orderParam = ''
    }
    if (this.orderParam !== '') {
      this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }


}