import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { CouponCodeService } from 'src/app/_business-dashboard/_services/bizz-coupon-codes.services';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomerCouponCodeService } from 'src/app/_services/coupon-codes.services';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';


@Component({
  selector: 'app-coupon-codes-list',
  templateUrl: './coupon-codes-list.component.html',
  styleUrls: ['./coupon-codes-list.component.scss']
})
export class CouponCodesListComponent implements OnInit {


  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  dataList: any = null
  searchText = ''
  lastSearchText = ''
  masterDataList: any = null
  isViewOnly = false
  orderParam = ''
  orderBy = 'asc'

  @ViewChild('p', { static: false }) paginator: Paginator;

  colHeader = [['#','Coupon Name','Status','Code','Discount Type', 'Value']];
  colflds =  ['index','discountName', 'status', 'couponCodes',  'discountValueType', 'discountValue'];

  constructor(
    public couponCodeServices: CustomerCouponCodeService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Coupon Codes'
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }
      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.filterValue) this._filterService.searchText = filter.filterValue
      } 
      clearInterval(ti)
      this.getDataList()
    }, 500);
  }

  getDataList() {
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    const obj = {
      "PageNo": this.page,
      "PageSize": this.rows,
      "filterValue": this._filterService.searchText,
      "discountValue": this._filterService.selectedCouponCodeDiscountType !== null ? this._filterService.selectedCouponCodeDiscountType.id: null,
      "orderParam":this.orderParam,
      "orderBy":this.orderBy,
    }

    this._abd.showSpinner()
     try {
       this.couponCodeServices.getAllCouponCodes(obj).subscribe(res => {
         if (res.success) {
           this.totalRecords = res.totalRecords
           let dataList = res.data
           let startFrom = this.page * this.rows+1
           this.dataList = dataList.map((value,index)=>{
            return{
              index:index+startFrom,...value
            }
          })
          this.masterDataList = this.dataList;
       
           this._abd.hideSpinner()

        }
      })

      this._filterService.updateFilters(this._globals.componentName, obj)
      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    } catch { this._abd.hideSpinner() }
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  getDiscountType(value:number){
    if( value === 0){
      return 'Flat';
    }else if(value === 1){
      return 'Percentage';
    }else{
      return 'Tracking Only'
    }
  }
  getDiscountValue(value:number,type:number){
    if( type === 0){
      return '$'+value;
    }else if(type === 1){
      return value+'%';
    }else{
      return '-'
    }
  }

  refrestDataList(e: any) {
    this.getDataList();
  }
  optionDeleteClicked() {
    this.op.hide()
    this._confirmationService.confirm({
      message: 'Deleting process causes permanent loss of information. Are you sure to proceed',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this.couponCodeServices.deleteCoupon(this.couponCodeServices.activeItem.id).subscribe(res => {
            if (res.success) {
              this.getDataList();
              this._connMessageService.showToastMessage(`Sales Channel deleted!`, 'success')
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }
  actionButtonClick(item: any) {
    this.couponCodeServices.activeItem = JSON.parse(JSON.stringify(item))
  }
  editButtonClicked() {
    this.isViewOnly = false
    this.couponCodeServices.showEditCoupon = true;
  }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.getDataList()
  }

  onPageChange(e) {
    this.page = e.page
    this.rows = e.rows
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }

 exportExcel() {
  let list = this.dataList.map((v)=>{
    return{
        ...v,status:v.isActive
    }
})
    this._abd.exportExcel(list, this.colHeader[0], this.colflds, 'Coupon Codes',false)
  }

  exportPdf() {
      let list = this.dataList.map((v)=>{
    return{
        ...v,status:v.isActive
    }
})
    this._abd.exportPdf(list, this.colHeader, this.colflds, 'Coupon Codes', false,false,false)
  }

  onRowSelect(e) {
    this.isViewOnly = true
    this.couponCodeServices.activeItem = JSON.parse(JSON.stringify(e.data))
    this.couponCodeServices.showEditCoupon = true;
  } 

  customSort(e) {
    const last = this.orderParam
    switch (e.sortField) {

      case 'discountName':
        this.orderParam = 'discountName'
        break;
      case 'discountValueType':
        this.orderParam = 'discountValueType'
        break;
      case 'discountValue':
        this.orderParam = 'discountValue'
        break;
      case 'isActive':
        this.orderParam = 'isActive'
        break;
      default:
        this.orderParam = ''
    }
    if (this.orderParam !== '') {
      this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }

}
