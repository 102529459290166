<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <!-- <div class="conn-grid" style="margin-top: 1px"> -->
        <div></div>
        <button pButton pRipple type="button" 
        label="View" class="p-button-text" [style]="{ width: '100%' }"
            icon="pi pi-eye" iconPos="right" (click)="ViewCustomer()"></button>
        <!-- </div> -->
    </ng-template>
</p-overlayPanel>
<div class="conn-card" *ngIf="1 == 1">
    <app-report-top-filter-bar
        [paraReportName]="'sales-invoices'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    ></app-report-top-filter-bar>

    <br />
    <p-table
        #dt
        [columns]=""
        styleClass="p-datatable-striped"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        [customSort]="true"
        selectionMode="single"
        [(selection)]="selectedRow"
        dataKey="invoiceNumber"
        (onRowSelect)="onRowSelected($event)"
        [lazy]="true"
        (onLazyLoad)="customSort($event)"
        *ngIf="dataList?.length > 0"
    >
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="invoiceNumber"><p-sortIcon field="itemName"></p-sortIcon>Order #</th>
                <th pSortableColumn="date"><p-sortIcon field="date"></p-sortIcon>Date-Time</th>
                <th pSortableColumn="customer"><p-sortIcon field="customer"></p-sortIcon>Customer</th>
                <th pSortableColumn="salesMode"><p-sortIcon field="salesMode"></p-sortIcon>Mode</th>
                <th pSortableColumn="transactionTypeString"><p-sortIcon field="transactionTypeString"></p-sortIcon>Txn Type</th>
                <th pSortableColumn="postedStatusString"><p-sortIcon field="postedStatusString"></p-sortIcon>Status Type</th>
                <th pSortableColumn="location"><p-sortIcon field="location"></p-sortIcon>Location</th>
                <th pSortableColumn="register"><p-sortIcon field="register"></p-sortIcon>Register</th>
                <th pSortableColumn="cashier"><p-sortIcon field="cashier"></p-sortIcon>Cashier</th>
                <th style="text-align: right">Grand Total</th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item">
                <td>{{ item.invoiceNumber }}</td>
                <td>{{ item.date | date : "mediumDate" }} {{ item.time }}</td>
                <td>{{ item.customer }}</td>
                <td>{{ item.salesMode }}</td>
                <td>{{ item.transactionTypeString }}</td>
                <td>{{ item.postedStatusString }}</td>
                <td>{{ item.location }}</td>
                <td>{{ item.register }}</td>
                <td>{{ item.cashier }}</td>
                <td style="text-align: right; font-weight: 700">
                    {{ item.purchase | currency }}
                </td>
                    <td style="text-align: center">
                        <button
                            pButton
                            pRipple
                            icon="pi pi-angle-down"
                            iconPos="right"
                            type="button"
                            label="Actions"
                            class="n-action-button"
                            (click)="op.toggle($event)"
                            (click)="actionButtonClick(item)"
                        ></button>
                    </td>
                
            </tr>
        </ng-template>
    </p-table>
    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="text-align-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
    <p-paginator
        #p
        [first]="first"
        *ngIf="dataList?.length>0"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
    ></p-paginator>

</div>
