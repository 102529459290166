<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [value]="searchList"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.skuCode }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice : 0 : 48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>
<form
 [formGroup]="editform"
 autocomplete="off"
 (ngSubmit)="editform.valid && saveFn()"
>
 <div class="conn-card">
     <div class="conn-grid-2x">
         <div>
             <label class="control-label">
                 <strong>
                     Coupon Name
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <app-text-input
                 [formControl]="$any(editform.controls)['discountName']"
                 [name]="'Coupon Name'"
                 [label]=""
             >
             </app-text-input>
         </div>
         <div>
            <label class="control-label">
                <strong>
                    Status
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <p-dropdown
                [formControl]="$any(editform.controls)['isActive']"
                [style]="{}"
                class="p-dropdown-1"
                [options]="statusList"
                optionLabel="viewValue"
                [autoDisplayFirst]="false"
            >
            </p-dropdown>
            <div *ngIf="$any(editform.controls)['isActive'].invalid && $any(editform.controls)['isActive'].touched" class="invalid-feedback">
                Please enter a Status
            </div>
        </div>
         <div>
             <label class="control-label">
                 <strong>
                    Sales Channel
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-dropdown
                 [formControl]="$any(editform.controls)['appNotificationText']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="salesChannelList"
                 optionLabel="viewValue"
                 [autoDisplayFirst]="false"
               ></p-dropdown>
             <div *ngIf="$any(editform.controls)['appNotificationText'].invalid && $any(editform.controls)['appNotificationText'].touched" class="invalid-feedback">
                Please enter a Sales Channel
            </div>
         </div>

         <div>
             <label class="control-label">
                 <strong>
                    Discount Type
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-dropdown
                 [formControl]="$any(editform.controls)['discountValueType']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="discountTypeList"
                 optionLabel="viewValue"
                 [autoDisplayFirst]="false"
             ></p-dropdown>
             <div *ngIf="$any(editform.controls)['discountValueType'].invalid && $any(editform.controls)['discountValueType'].touched" class="invalid-feedback">
                Please enter a Discount Type
            </div>
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Value <span *ngIf="f.discountValueType?.value?.id ==0">
                        ($)
                    </span>
                    
                     <span *ngIf="f.discountValueType?.value?.id == 1">
                         (%)
                     </span>
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />
             <app-text-input
             [formControl]="$any(editform.controls)['discountValue']"
             [name]="'Value'"
             [label]=""
         ></app-text-input>
         <div class="invalid-feedback" *ngIf="editform.get('discountValue').hasError('maxFractionalDigits')">
            Maximum of 2 fractional digits allowed after decimal.
          </div>        
       </div>

         <div *ngIf="f.discountValueType?.value?.id == 1">
             <label class="control-label">
                 <strong>
                    Max Discount ($)
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />
             <app-text-input
             [formControl]="$any(editform.controls)['maximumAllowed']"
             [name]="'Value'"
             [label]=""
             [description]="maxDiscountDesc"
              ></app-text-input>
              <div class="invalid-feedback" *ngIf="editform.get('maximumAllowed').hasError('maxFractionalDigits')">
                Maximum of 2 fractional digits allowed after decimal.
              </div> 
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Max Coupon Use
                 </strong>
             </label>
             <br />
             <app-text-input
             [formControl]="$any(editform.controls)['maxCouponUse']"
             [name]="'Max Coupon Use'"
             [label]=""
             [description]="maxCouponUseDesc"
              ></app-text-input>
             <br />
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Validity
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-dropdown
                 [formControl]="$any(editform.controls)['validity']"
                 [style]="{}"
                 class="p-dropdown-1"
                 [options]="validityList"
                 optionLabel="viewValue"
             >
             </p-dropdown>
             <div *ngIf="$any(editform.controls)['validity'].invalid && $any(editform.controls)['validity'].touched" class="invalid-feedback">
                Please enter a Validity
            </div>
         </div>

         <div *ngIf="f.validity?.value?.id == 1 && f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Date Range
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-calendar
                 [formControl]="$any(editform.controls)['validityDate']"
                 selectionMode="range"
                 [style]="{}"
                 [readonlyInput]="false"
                 inputId="multiple"
                 class="filter"
                 baseZIndex="0"
                 appendTo="body"
             >
             </p-calendar>
             <div *ngIf="$any(editform.controls)['validityDate'].invalid && $any(editform.controls)['validityDate'].touched" class="invalid-feedback">
                Please enter a Date Range
            </div>
         </div>

         <div *ngIf="f.discountValueType?.value?.id !== 2">
             <label class="control-label">
                 <strong>
                     Allowed days
                     <span style="color: red; font-size: 1.25rem">*</span>
                 </strong>
             </label>
             <br />

             <p-multiSelect
                 [formControl]="$any(editform.controls)['allowedDaysList']"
                 [style]="{}"
                 [options]="allowedDaysList"
                 optionLabel="viewValue"
                 [filter]="false"
                 [showToggleAll]="true"
             >
             <ng-template pTemplate="header">
                <div class="checkbox-all-text">Select All</div>
              </ng-template>
             </p-multiSelect>
             <div *ngIf="$any(editform.controls)['allowedDaysList'].invalid && $any(editform.controls)['allowedDaysList'].touched" class="invalid-feedback">
                Please enter a Allowed Days
            </div>
         </div>

        <div *ngIf="f.discountValueType?.value?.id !== 2">
            <label class="control-label">
                <strong>
                  Applied On<span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <p-dropdown
                [formControl]="$any(editform.controls)['applyOn']"
                [style]="{}"
                class="p-dropdown-1"
                [options]="appliedOn"
                optionLabel="viewValue"
                [autoDisplayFirst]="true"
            >
            </p-dropdown>
            <div *ngIf="$any(editform.controls)['applyOn'].invalid && $any(editform.controls)['applyOn'].touched" class="invalid-feedback">
                Please enter Appied On
            </div>
        </div>
        <div *ngIf="f.discountValueType?.value?.id !== 2 && f.applyOn?.value.id === 2">
            <label class="control-label">
                <strong>
                    Product
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <div class="p-inputgroup w-full">
                <input
                    type="text"
                    pInputText
                    placeholder="Quick search product"
                    [(ngModel)]="searchText"
                    [ngModelOptions]="{standalone: true}"
                    (keydown.enter)="searchButtonClick($event)"
                    [disabled]="isLoading"
                />
                <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-search"
                    [loading]="isLoading"
                    (click)="searchButtonClick($event)"
                    [disabled]="isLoading"
                ></button>
            </div>
        </div>
      
      
        <div *ngIf="f.discountValueType?.value?.id !== 2 && f.applyOn?.value.id === 3">
            <label class="control-label">
                <strong>
                  Category
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong>
            </label>
            <br />
            <p-multiSelect  
            [formControl]="$any(editform.controls)['category']"
            [style]="{ width: '100%', 'max-width':'575px'}"
            class="p-dropdown-1"
            [options]="_abd.categories$ | async"
            optionLabel="categoryName"            
            [filter]="true"
            filterBy="categoryName"
            [autoDisplayFirst]="false"
        >
        </p-multiSelect>
            <!-- <p-dropdown
          


        ></p-dropdown> -->
            <div *ngIf="$any(editform.controls)['category'].invalid && $any(editform.controls)['category'].touched" class="invalid-feedback">
                Please enter category
            </div>
        </div>
     </div>
<!-- Table -->
<div class="flex justify-content-center py-2 " *ngIf="isProductLoad && !isNew && f.discountValueType?.value?.id !== 2">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem;color:var(--brandColor)"></i>
</div>
<br />

<p-table
    #www
    *ngIf="dataList.length > 0 && f.discountValueType?.value?.id !== 2 && f.applyOn?.value.id === 2"
    [value]="dataList"
    [autoLayout]="true"
    responsiveLayout="scroll"
    showLoader="true"
    rowHover="true"
>
    <ng-template pTemplate="header">
        <tr>
           <!-- <th style="text-align: center; width: 30px">
                <p-checkbox
                    *ngIf="!isViewOnly"
                    [binary]="true"
                    (onChange)="selectAllChanged($event)"
                    [disabled]="isViewOnly || !dataList.length"
                ></p-checkbox>
            </th>  -->
            <th>Barcode</th>
            <th>SKU</th>
            <th>Product</th>
            <!-- <th>Location</th> -->
            <th>Price</th>
            <th>Action</th>

        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
        <tr>
            <!-- <td style="text-align: center">
                <p-checkbox
                    *ngIf="!isViewOnly"
                    [(ngModel)]="item.selected"
                    [binary]="true"
                    (onChange)="selectionChanged($event)"
                    [disabled]="isViewOnly"
                ></p-checkbox>
            </td> -->
            <td >{{ item.barcode }}</td>
            <td >{{ item.skuCode }}</td>
            <td >{{ item.productName }}</td>
            <!-- <td >{{ item.locationName}}</td> -->
            <td >{{ item.salePriceA | currency}}</td>
            <td class="cursor-pointer flex justify-content-center" (click)="removeItem(item)" style="color: red;" ><i class="pi pi pi-times"></i></td>
           
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" style="text-align: center">
        <tr>
            <td colspan="16" style="text-align: center">
                There is no data to show.
            </td>
        </tr>
    </ng-template>
</p-table>
<!-- Table -->
     <div>
         <label class="control-label">
             <strong>
                 Coupon Codes 
                 <span style="color: red; font-size: 1.25rem">*</span>
             </strong>
         </label>
         <br />
         <p-chips
             type="text"
             [formControl]="$any(editform.controls)['couponCodesList']"
             separator=","
             addOnBlur="true"
             (onAdd)="validateKey($event)"
             [allowDuplicate]="false"
             [max]="1"
         ></p-chips>
         <!-- (i.e., AbC123,123ABC,ABC123DEF) -->
         <span class="control-description text-muted" >
        <u>Instructions:</u>
        <li>One code for one coupon only.</li>
        <li>Coupon code must not contain special characters. </li>
        <li>Please use minimum 4 and maximum 10 alphanumeric characters only.</li>
            <!-- One code for one coupon only.Coupon code must not contain special characters. Please use minimum 4 and maximum 10 alphanumeric characters only. -->
        </span>
         <!-- <span class="control-description text-muted" >One code for one coupon only.(Max code characters allowed = 10, i.e., AbC%123456)</span> -->
         <div *ngIf="$any(editform.controls)['couponCodesList'].touched ">
            <div
            *ngIf="$any(editform.controls)['couponCodesList'].invalid"
            class="invalid-feedback"
        >
               Please enter a Code
        </div>
         </div>
     </div>
     <div>
        <label class="control-label">
            <strong>
                URL 
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong>
        </label>
        <br />
        <app-text-input
        [formControl]="$any(editform.controls)['printText']"
        [name]="'Url'"
        [label]=""
    ></app-text-input>
    <div *ngIf="genratedLink">
        <span class="control-description text-muted">{{genratedLink}}</span> &nbsp;&nbsp;&nbsp;<i style="color: var(--brandColor);" class="pi pi-copy cursor-pointer" (click)="copy(genratedLink)"></i>
    </div>

  </div>
 </div>

 <!-- <br /> -->
 <p-divider style="margin-top: 10px"></p-divider>
 <div class="grid">
     <div class="col">
         <div
             class="flex justify-content-end flex-wrap card-container green-container mt-3"
         >
             <div class="flex align-items-center justify-content-center">
                 <button
                     pButton
                     pRipple
                     type="submit"
                     label="Save"
                     [disabled]="!editform.valid"
                     *ngIf="!isViewOnly"
                     [style]="{ width: '120px' }"
                 ></button>
             </div>
             <div
                 class="flex align-items-center justify-content-center ml-2"
             >
                 <button
                     pButton
                     pRipple
                     type="button"
                     label="Cancel"
                     class="p-button-outlined p-button-danger"
                     (click)="cancelButtonClick()"
                     [style]="{ width: '120px' }"
                 ></button>
             </div>
         </div>
     </div>
 </div>
</form>


